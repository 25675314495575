export function initializeWhatsAppPG(whatsApp){
    const pgScript = document.createElement('script')
    pgScript.type = 'text/javascript'
    pgScript.innerHTML = `
        (function () {
            var options = {
                whatsapp: "${whatsApp}", // WhatsApp number
                pre_filled_message: 'Olá, tudo bem? Gostaria de saber mais sobre o POS CONTROLE.',
                call_to_action: "Fale com a gente", // Call to action
                position: "right", // Position may be 'right' or 'left'
            };
            var proto = document.location.protocol, host = "getbutton.io", url = proto + "//static." + host;
            var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = url + '/widget-send-button/js/init.js';
            s.onload = function () { WhWidgetSendButton.init(host, proto, options); };
            var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
        })();
    `
    return document.body.appendChild(pgScript)
}