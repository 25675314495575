import axios from "axios";

const instance = axios.create({
  baseURL: "https://app.poscontrole.com.br/app",
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

export const createUser = async (nome, email, telefone, cnpj,qntPos, price ) => {
  return instance.post("/prc.php", {
    nome: nome,
    email: email,
    telefone: telefone.replace('(', '').replace(')', '').replace('-', ''),
    cnpj: cnpj.replaceAll('.', '').replace('/', '').replace('-', ''),
    qntPos,
    lcnsPrc: price
  });
};
