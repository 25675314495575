import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    :root {
        --color-primary: #1B4472;
        --color-secondary: #255B99;
        --color-accent: #EF743B;
        --color-secondary-accent: #E6BE69;

        --color-dark: rgba(0,0,0, 0.87);
        --color-dark-medium: rgba(0,0,0,0.54);
        --color-light: #fff;
        --color-green: #31AE17;
        --color-red: #AE1717;
        --color-lightOpacity: rgba(255, 255, 255, 0.3);
        
        --font-family-primary: Gotham;
        --font-family-secondary: Raleway, sans-serif;

        --color-dark: rgba(0,0,0, 0.87);
        --color-dark-medium: rgba(0,0,0,0.54);
        --color-background: #F5F8F9;
        --color-light: #fff;
        --color-green: #31AE17;
        --color-red: #AE1717;
        --color-lightOpacity: rgba(255, 255, 255, 0.3);

        --box-shadow-card: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
        --box-shadow-nav: 0px 6px 10px 2px rgba(0, 0, 0, 0.06);

        --box-shadow-button-up: 0px 10px 20px 3px rgba(0, 0, 0, 0.20);
        --box-shadow-button-hover: 0px 5px 20px 3px rgba(0, 0, 0, 0.20);
        --box-shadow-button-acive: 0px 0px 20px 3px rgba(0, 0, 0, 0.20);

        --border-radius-large: 12px;
        --border-radius-medium: 10px;
        --border-radius-small: 8px;
    }
`