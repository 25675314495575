import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;
  padding: 40px;
  position: relative;
  .card {
    border: solid 1px #f0e9f3;
    display: flex;
    height: 600px;
    width: 85%;
    margin: 10px auto;
    border-radius: 25px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
    min-width: 380px;
    max-width: 1024px;
  }
  ion-icon {
    color: black;
    margin: 10px 0;
    font-size: 40px;
  }
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    margin-right: 7%;
    &:hover {
      opacity: 0.3;
    }
  }
  .card__form {
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      70deg,
      var(--color-accent),
      var(--color-secondary-accent)
    );
    align-items: start;
    text-align: left;
    justify-content: center;
    border-radius: 25px;
    gap: 20px;
    height: 100%;
    right: 0;
    width: 55%;
    position: absolute;
    margin: 0 auto;
    @media (max-width: 1024px) {
      border-radius: 18px;
      width: 100%;
    }
    h1 {
      font-size: 44px;
      color: #ffffff;
      margin: 0 0 32px 0;
    }
    button {
      border: none;
      background: var(--color-primary);
      color: #ffffff;
      width: 180px;
      height: 50px;
      border-radius: 25px;
      font-size: 23px;
      font-weight: bold;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
  .form__group {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    label {
      font-size: 22px;
      color: #123142;
    }
    input {
      width: 95%;
      height: 40px;
      padding: 4px;
      font-size: 15px;
      color: gray;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
      outline: none;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      ::placeholder {
        color: var(--color-accent);
        opacity: 0.6;
      }
    }
  }
  .content__form {
    margin: 0 55px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .card__image {
    width: 50%;
    height: 100%;
    border-radius: 18px 0 0 18px;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .error {
    color: red;
    margin: -8px 0;
  }
`;
export const ButtonModal = styled.button`
  border: none;
  width: 150px;
  height: 60px;
  font-size: 20px;
  color: gray;
  border-radius: 8px;
  &:hover {
    opacity: 0.8;
  }
`;
