import React from 'react'
import {Container} from './styles'

function Button({text, id, tabIndex, type='button', styles, className, onClick, icon}) {
    return (
        <Container id={id} tabIndex={tabIndex} type={type} onClick={onClick} className={className} style={{...styles}}>
            {icon && <ion-icon name={icon}></ion-icon>}
            {text && text}
        </Container>
    )
}

export default Button
