import React from "react";
// import { Logo } from "../../assets/images";
import { Container } from "./styles";
import { createGaEvent } from "../../services/analytics";
import Logo from "../../assets/images/footer.png";
function Footer() {
  const navigate = (id, val = 0) => {
    const offSetID = document.getElementById(id)
      ? document.getElementById(id).offsetTop - val
      : 0;
    goTop(offSetID);
    createGaEvent("AnchorTag", id);
  };
  const goTop = (top) => {
    try {
      window.scroll({
        top: top || 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {/* <Partners>
          <div style={{ flex: 3 }} className="partners_container">
            <h2>Adquirentes</h2>
            <div className="partner_images many">
              <img alt="Adquirente ADIQ" src={AD_ADIQ} />
              <img alt="Adquirente CIELO" src={AD_CIELO} />
              <img alt="Adquirente ELGIN" src={AD_ELGIN} />
              <img alt="Adquirente GETNET" src={AD_GETNET} />
              <img alt="Adquirente PASSWORD" src={AD_PAGSEGURO} />
              <img alt="Adquirente REDE" src={AD_REDE} />
              <img alt="Adquirente STONE" src={AD_STONE} />
              <img alt="Adquirente SAFRAPAY" src={AD_SAFRAPAY} />
              <img alt="Adquirente VERO" src={AD_VERO} />
              <img alt="Adquirente Fiserv" src={FISERV} />
            </div>
          </div>
          <div style={{ flex: 2 }} className="partners_container">
            <h2>Fabricantes</h2>
            <div className="partner_images many">
              <img
                style={{ maxHeight: 50 }}
                alt="Fabricante INGENICO"
                src={FA_INGENICO}
              />
              <img alt="Fabricante GERTEC" src={FA_GERTEC} />
              <img alt="Fabricante NEWLAND" src={FA_NEWLAND} />
              <img
                style={{ maxHeight: 50 }}
                alt="Fabricante PAX"
                src={FA_PAX}
              />
              <img alt="Fabricante VERIFONE" src={FA_VERIFONE} />
              <img alt="Adquirente Elgin" src={ELGIN} />
              <img alt="Adquirente TecToy" src={TECTOY_AUTO} />
              <img alt="Adquirente Positivo" src={POSITIVO} />
            </div>
          </div>
          <div className="partners_container">
            <h2>Conectores</h2>
            <div className="partner_images many">
              <img
                style={{ maxHeight: 50 }}
                alt="Conectores SITEF"
                src={X_SITEF}
              />
              <img alt="Conectores GER7" src={X_GER7} />
              <img alt="Conectores PAYGO" src={X_PAYGO} />
              <img alt="Conectores GSURF" src={X_GSURF} />
              <img alt="Conectores API" src={X_API} />
            </div>
          </div>
          <div className="partners_container">
            <h2>Outros</h2>
            <div className="partner_images few">
              <img alt="Outro AME" src={OU_AME} />
              <img style={{ maxHeight: 50 }} alt="Outro RAPI" src={OU_RAPI} />
              <img
                style={{ maxHeight: 80 }}
                alt="Outro TECBAN"
                src={OU_TECBAN}
              />
            </div>
          </div>
        </Partners> */}
      <Container>
        <div className="footer_1">
          <div>
            <img src={Logo} alt="Logo" />
          </div>
          <div className="voce_pos">
            <h3>Você na POS</h3>
            <a
              href="https://app.poscontrole.com.br/POSAuth/"
              target="_blank"
              rel="noreferrer"
            >
              Login
            </a>
            <a
              href="https://oferta.poscontrole.com.br/indiqueeganhe"
              target="_blank"
              rel="noreferrer"
            >
              Indique a POS e ganhe PIX
            </a>
          </div>
        </div>
        <div className="footer_2">
          <div className="help_pos">
            <h3>Descubra como podemos lhe ajudar</h3>
            <a
              href="https://oferta.poscontrole.com.br/sistemas-integrados"
              target="_blank"
              rel="noreferrer"
            >
              Sistemas integrados
            </a>
            <a
              href="https://oferta.poscontrole.com.br/treinamento-clientefinal"
              target="_blank"
              rel="noreferrer"
            >
              Materiais de ajuda
            </a>
            <a
              href="https://www.api.poscontrole.com.br/"
              target="_blank"
              rel="noreferrer"
            >
              Portal de API pública
            </a>
          </div>

          <div className="canais_pos">
            <h3>Comercial:</h3>
            <p>(11) 91425-1035</p>
            <a href="mailto:comercial@poscontrole.com.br">
              comercial@poscontrole.com.br
            </a>
            <h3>Suporte:</h3>
            <p>(71) 3599-0958</p>
            <a href="mailto:atendimento@poscontrole.com.br">
              atendimento@poscontrole.com.br
            </a>
            <div className="social_icons">
              <ion-icon
                onClick={() => {
                  createGaEvent("link_sm", "Instagram POS CONTROLE");
                  window.open(
                    "https://www.instagram.com/poscontrole/?hl=pt-br"
                  );
                }}
                name="logo-instagram"
              ></ion-icon>
              <ion-icon
                onClick={() => {
                  createGaEvent("link_sm", "Facebook POS CONTROLE");
                  window.open("https://www.facebook.com/poscontrole/");
                }}
                name="logo-facebook"
              ></ion-icon>
            </div>
          </div>
        </div>
        {/* <div style={{ alignItems: 'center', marginLeft: '2%' }} className='footer_links'>
            </div>
            <div className='footer_content'>

                <div className='footer_links'>
                    <p className='hiperlink' onClick={() => navigate('', 120)}>O que é</p>
                    <p className='hiperlink' onClick={() => navigate('como_utilizar', 90)}>Como Utilizar</p>
                    <p className='hiperlink' onClick={() => { createGaEvent('link', 'Revenda'); window.open('http://revenda.poscontrole.com.br') }}>Seja uma Revenda</p>
                    <p className='hiperlink' onClick={() => { createGaEvent('link', 'APP POS CONTROLE'); window.open('https://app.poscontrole.com.br') }} to='/login'>Login</p>
                </div>
                <div className='footer_links'>
                    <h3>Atendimento</h3>
                    <div className='iconed_text'>
                        <ion-icon name='call-outline' />
                        <div>
                            <p>(11) 91425-1035</p>
                            <p>(71) 3599-0958</p>
                        </div>

                    </div>
                    <div className='iconed_text'>
                        <ion-icon name='mail-outline' />
                        <div>
                            <p align="right"><a href="mailto:comercial@poscontrole.com.br">comercial@poscontrole.com.br</a></p>
                            <p align="right"><a href="mailto:atendimento@poscontrole.com.br">atendimento@poscontrole.com.br</a></p>
                        </div>
                    </div>
                </div>
                <div style={{ paddingRight: 0 }}  className='footer_links'>
                    <h3>Comunidades</h3>
                    <div className='partner_images'>
                        <img style={{ minHeight: 80 }} alt='Cubo' src={CO_CUBO} />
                        <img style={{ minHeight: 80 }} alt='Hub Salvador' src={CO_HUB} />
   
                        <img alt='Conexão Rede' style={{ minHeight: 25, margin: '0 5px' }}  src={CO_CONEXAO} />
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt='Afrac' src={CO_AFRAC} />
                        <img style={{ minHeight: 90 }} alt='Selo Afrac' src={SELO_AFRAC} />
                        </div>
     
                    </div>
                </div>
            </div> */}
      </Container>
    </>
  );
}

export default Footer;
