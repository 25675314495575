import 'animate.css';
import React, { useEffect } from 'react'
import Routes from './config/routes'
import './App.css';
import Theme from './assets/theme'
import {initializeReactGA} from './services/analytics'
import initializePixel from './services/pixels'
import {initializeWhatsAppPG} from './services/whatsapp'
import Nav from './shared_components/nav';
import { Footer } from './shared_components';
function App() {

  useEffect(() => {
    initializePixel()
    initializeReactGA()
    initializeWhatsAppPG('+5511914251035')
  
  }, [])

  return (
    <div className="App">
      <Theme/>
      <Nav />
      <Routes/>
      <Footer />
    </div>
  );
}

export default App;
