/* eslint-disable import/no-anonymous-default-export */
// Por último, importamos tudo e exportamos um único objeto

import ptBrTranslations from './pt-br'
import enUsTranslations from './en-us'

export default {
  'pt-BR': ptBrTranslations, // Atente-se a usar as abreviaturas corretas
  'en-US': enUsTranslations // https://support.mozilla.org/pt-BR/kb/abreviacao-de-localizacao
}
