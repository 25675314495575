import ReactGA from 'react-ga4';

export const initializeReactGA = () => {
    debugger
    ReactGA.initialize('G-R003218Y98');
    // ReactGA.pageview(window.location.pathname + window.location.search)
}
ReactGA.send({ hitType: "pageview", page: "/", title: "Cliente Final" });
ReactGA.send({ hitType: "pageview", page: "/revenda", title: "Revenda" });
export const createGaEvent = (category, action) => {
    ReactGA.event({
        category: category,
        action: action
    });
}
