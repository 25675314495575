import styled from 'styled-components'

export const CONTAINER = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    width: 100%;
    
    h5 {
        font-size: 16px;
        color: var(--color-dark);
        margin: 10px;
    }

    .mask_input_container {
        border: 1px solid #CBDCE2;
        border-radius: 8px;
        padding: 8px 8px;
        background: white;
        display: flex;
        align-items: center;
        overflow: hidden;
        
        img {
            max-height: 20px;
            margin-right: 10px;
        }
    
        input  {
            font-size: 14px;
            width: 100%;
            outline: none;
            background: none;
            border: none;

            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: rgba(0,0,0, 0.54);
                opacity: 1; /* Firefox */
              }
              
              :-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: white;
              }
              
              ::-ms-input-placeholder { /* Microsoft Edge */
                color: white;
              }
        }
    
        
    }
 



`