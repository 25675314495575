import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { TextTop, Container, Description, CardA, CardB, ImplatatioValue } from "./style";
import { FaPlus, FaMinus, FaCheck } from "react-icons/fa";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../../custom_hooks";
import ToastPlan from "../toast";
import { createGaEvent } from "../../../../services/analytics";

export default function Plans({ openModal }) {
  const [counter, setCounter] = useState(1);
  const [counterPlus, setCounterPlus] = useState(1);
  const [open, setOpen] =  useState(false)
  const { width } = useWindowSize();
  const { t } = useTranslation();
  let history = useHistory();
  const addCounter = () => {
    if (counter === 11) {
      setOpen(true)
      return
    };
    setCounter((prevState) => prevState + 1);
  };

  const subCounter = () => {
    if (counter === 1) return;
    setCounter((prevState) => prevState - 1);
  };
  const addCounterPlus = () => {
    if (counterPlus === 11) {
      setOpen(true)
      return
    };
    setCounterPlus((prevState) => prevState + 1);
  };

  const subCounterPlus = () => {
    if (counterPlus === 1) return;
    setCounterPlus((prevState) => prevState - 1);
  };
  const [sliderRef] = useKeenSlider({
    slides: {
      origin: "center",
      perView: 1.2,
      spacing: 0,
    },
    breakpoints: {
      "(max-width: 1049px)": {
        loop: false,
        slides: {
          origin: "center",
          perView: 1.3,
          spacing: 10,
        },
      },
    }
  });
  return (
    <Container id="main">
      <TextTop>
        <h1>Sua mudança começa AGORA!</h1>
        <h2>Conheça nossos planos e preços</h2>
        {/* <img style={{ width: '15em', marginTop: 5 }} src={Logo} alt='Logo POS'/> */}
        {/* <p style={{ fontWeight: 'bold',  marginLeft: 12,  fontSize: 25   }} >e saiba mais:</p> */}
      </TextTop>
      <br />
      <br />
      {width > 1049 ? (
        <div className="plans">
          <div className="plan__a">
            <CardA>
              <div className="content__card">
                <h2>POS FICHA</h2>
                <div className="plan_a_price">
                  <div>

                  <h1>
                    R${(counter * 99)
                      .toString()
                      .replace(/(\d)(\d{3})/, "$1.$2")}
                    <span>
                      ,00<p>{t("por mês")}</p>
                    </span>
                  </h1>
                    <ImplatatioValue>Implantação: R$ 300,00</ImplatatioValue>
                  </div>
                  <div className="select_qnt">
                    <div className="controls__quatity">
                      <button onClick={() => {
                          subCounter()
                        }}>
                        <FaMinus color="#407295" size={14} />
                      </button>
                      <div
                        style={{
                          display: "inline",
                          width: 20,
                          color: "white",
                          fontSize: 20,
                          fontWeight: "bold",
                          marginInline: 12,
                          fontFamily: "Montserrat",
                        }}
                      >
                        {counter}
                      </div>
                      <button onClick={() => {
                          addCounter()
                        }}>
                        <FaPlus color="#407295" size={14} />
                      </button>
                    </div>
                    <p>
                      Escolha a quantidade de PDV’s que irá utilizar o sistema.
                    </p>
                  </div>
                </div>
                {counter <= 10 ? (
                  <button
                    className="button_sale"
                    onClick={() => {
                      createGaEvent('Usuario', 'lp_nova_teste')
                      history.push({
                        pathname: "/checkout",
                        state: {
                          qntPos: counter,
                          price: 99.00
                        },
                      });
                    }}
                  >
                    {t("Contrate agora")}
                  </button>
                ) : (
                  <button className="button_sale" onClick={openModal}>
                    {t("Fale com nossos especialistas")}
                  </button>
                )}

                <div className="list__card">
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>
                      {t(
                        "Realize vendas, emita Fichas, Pedidos/Senhas ou Ingressos diretamente de sua máquina de cartão de crédito."
                      )}
                    </p>
                  </Description>
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>
                      {t(
                        "Elimine a necessidade de computador, monitor e impressora do seu balcão."
                      )}
                    </p>
                  </Description>
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>
                      {t(
                        "Receba pagamentos em cartão de crédito/débito, dinheiro ou PIX."
                      )}
                    </p>
                  </Description>
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>
                      {t(
                        "Acompanhe suas vendas, em tempo real, no celular, computador ou tablet."
                      )}
                    </p>
                  </Description>
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>{t("Reduza custos operacionais.")}</p>
                  </Description>
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>
                      {t("Aumente a eficiência de atendimento do seu PDV.")}
                    </p>
                  </Description>
                </div>
              </div>
            </CardA>
          </div>
          <div className="plan__b">
            <CardB>
              <div className="content__card">
                <h2>POS NFC-E</h2>
                <div className="plan_a_price">
                  <div>

                  <h1>
                    R$
                    {(counterPlus * 128)
                      .toString()
                      .replace(/(\d)(\d{3})/, "$1.$2")}
                    <span>
                      ,00<p>por mês</p>
                    </span>
                  </h1>
                  <ImplatatioValue>Implantação: R$ 300,00</ImplatatioValue>
                      </div>
                  <div className="select_qnt">
                    <div className="controls__quatity">
                      <button onClick={() => {
                          subCounterPlus()
                        }}>
                        <FaMinus color="#407295" size={14} />
                      </button>
                      <div
                        style={{
                          display: "inline",
                          width: 20,
                          color: "white",
                          fontSize: 20,
                          fontWeight: "bold",
                          marginInline: 12,
                          fontFamily: "Montserrat",
                        }}
                      >
                        {counterPlus}
                      </div>
                      <button onClick={() => {
                          addCounterPlus()
                        }}>
                        <FaPlus color="#407295" size={14} />
                      </button>
                    </div>
                    <p>
                      Escolha a quantidade de PDV’s que irá utilizar o sistema.
                    </p>
                  </div>
                </div>
                {counterPlus <= 10 ? (
                  <button
                    className="button_sale"
                    onClick={() => {
                      createGaEvent('Usuario', 'lp_nova_teste')
                      history.push({
                        pathname: "/checkout",
                        state: {
                          qntPos: counterPlus,
                          price: 128.00
                        },
                      });
                    }}
                  >
                    {t("Contrate agora")}
                  </button>
                ) : (
                  <button className="button_sale" onClick={openModal}>
                    {t("Fale com nossos especialistas")}
                  </button>
                )}

                <div className="list__card">
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p style={{ fontWeight: "bold" }}>
                      {t(
                        "Emita Nota Fiscal do Consumidor Eletrônica (NFC-e) direto da sua máquina de cartão."
                      )}
                    </p>
                  </Description>
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>
                      {t(
                        "Realize vendas, emita pedidos/senha, fichas e ingressos diretamente de sua máquina de cartão de crédito."
                      )}
                    </p>
                  </Description>
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>
                      {t(
                        "Elimine a necessidade de computador, monitor e impressora do seu balcão."
                      )}
                    </p>
                  </Description>
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>
                      {t(
                        "Receba pagamentos em cartão de crédito/débito, dinheiro ou PIX."
                      )}
                    </p>
                  </Description>
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>
                      {t(
                        "Acompanhe suas vendas, em tempo real, no celular, computador ou tablet."
                      )}
                    </p>
                  </Description>
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>{t("Reduza custos operacionais.")}</p>
                  </Description>
                  <Description>
                    <di>
                      <FaCheck color="#ABE613" />
                    </di>
                    <p>
                      {t("Aumente a eficiência de atendimento do seu PDV.")}
                    </p>
                  </Description>
                </div>
              </div>
            </CardB>
          </div>
        </div>
      ) : (
        <div className="plans">
          <div ref={sliderRef} className="keen-slider">
            <div className="keen-slider__slide">
              <div className="plan__a">
                <CardA>
                  <div className="content__card">
                    <h2>POS FICHA</h2>
                    <div className="plan_a_price">
                      <div>

                      <h1>
                        R${(counter * 99)
                      .toString()
                      .replace(/(\d)(\d{3})/, "$1.$2")}
                        <span>
                          ,00<p>{t("por mês")}</p>
                        </span>
                      </h1>
                      <ImplatatioValue>Implantação: R$ 300,00</ImplatatioValue>
                      </div>
                      <div className="select_qnt">
                        <div className="controls__quatity">
                          <button onClick={() => {
                              subCounter()
                            }}>
                            <FaMinus color="#407295" size={14} />
                          </button>
                          <div
                            style={{
                              display: "inline",
                              width: 20,
                              color: "white",
                              fontSize: 20,
                              fontWeight: "bold",
                              marginInline: 12,
                              fontFamily: "Montserrat",
                            }}
                          >
                            {counter}
                          </div>
                          <button onClick={() => {
                            addCounter()
                            }}>
                            <FaPlus color="#407295" size={14} />
                          </button>
                        </div>
                        <p>
                          Escolha a quantidade de PDV’s que irá utilizar o
                          sistema.
                        </p>
                      </div>
                    </div>
                    {counter <= 10 ? (
                      <button
                        className="button_sale"
                        onClick={() => {
                          createGaEvent('Usuario', 'lp_nova_teste')
                          history.push({
                            pathname: "/checkout",
                            state: {
                              qntPos: counter,
                              price: 99.00
                            },
                          });
                        }}
                      >
                        {t("Contrate agora")}
                      </button>
                    ) : (
                      <button className="button_sale" onClick={openModal}>
                        {t("Fale com nossos especialistas")}
                      </button>
                    )}

                    <div className="list__card">
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>
                          {t(
                            "Realize vendas, emita Fichas, Pedidos/Senhas ou Ingressos diretamente de sua máquina de cartão de crédito."
                          )}
                        </p>
                      </Description>
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>
                          {t(
                            "Elimine a necessidade de computador, monitor e impressora do seu balcão."
                          )}
                        </p>
                      </Description>
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>
                          {t(
                            "Receba pagamentos em cartão de crédito/débito, dinheiro ou PIX."
                          )}
                        </p>
                      </Description>
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>
                          {t(
                            "Acompanhe suas vendas, em tempo real, no celular, computador ou tablet."
                          )}
                        </p>
                      </Description>
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>{t("Reduza custos operacionais.")}</p>
                      </Description>
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>
                          {t("Aumente a eficiência de atendimento do seu PDV.")}
                        </p>
                      </Description>
                    </div>
                  </div>
                </CardA>
              </div>
            </div>
            <div className="keen-slider__slide">
              <div className="plan__b">
                <CardB>
                  <div className="content__card">
                    <h2>POS NFC-E</h2>
                    <div className="plan_a_price">
                      <div>

                      <h1>
                        R$
                        {(counterPlus * 128)
                          .toString()
                          .replace(/(\d)(\d{3})/, "$1.$2")}
                        <span>
                          ,00<p>por mês</p>
                        </span>
                      </h1>
                      <ImplatatioValue>Implantação: R$ 300,00</ImplatatioValue>
                          </div>
                      <div className="select_qnt">
                        <div className="controls__quatity">
                          <button onClick={() => {
                              subCounterPlus()
                              }}>
                            <FaMinus color="#407295" size={14} />
                          </button>
                          <div
                            style={{
                              display: "inline",
                              width: 20,
                              color: "white",
                              fontSize: 20,
                              fontWeight: "bold",
                              marginInline: 12,
                              fontFamily: "Montserrat",
                            }}
                          >
                            {counterPlus}
                          </div>
                          <button onClick={ () => {
                            addCounterPlus()
                          }}>
                            <FaPlus color="#407295" size={14} />
                          </button>
                        </div>
                        <p>
                          Escolha a quantidade de PDV’s que irá utilizar o
                          sistema.
                        </p>
                      </div>
                    </div>
                    {counterPlus <= 10 ? (
                      <button
                        className="button_sale"
                        onClick={() => {
                          createGaEvent('Usuario', 'lp_nova_teste')
                          history.push({
                            pathname: "/checkout",
                            state: {
                              qntPos: counterPlus,
                              price: 128.00
                            },
                          });
                        }}
                      >
                        {t("Contrate agora")}
                      </button>
                    ) : (
                      <button className="button_sale" onClick={openModal}>
                        {t("Fale com nossos especialistas")}
                      </button>
                    )}

                    <div className="list__card">
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p style={{ fontWeight: "bold" }}>
                          {t(
                            "EMITA NOTA ELETRÔNICA DIRETAMENTE DA SUA MÁQUINA DE CARTÃO."
                          )}
                        </p>
                      </Description>
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>
                          {t(
                            "Realize vendas, emita Fichas, Pedidos/Senhas ou Ingressos diretamente de sua máquina de cartão de crédito."
                          )}
                        </p>
                      </Description>
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>
                          {t(
                            "Elimine a necessidade de computador, monitor e impressora do seu balcão."
                          )}
                        </p>
                      </Description>
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>
                          {t(
                            "Receba pagamentos em cartão de crédito/débito, dinheiro ou PIX."
                          )}
                        </p>
                      </Description>
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>
                          {t(
                            "Acompanhe suas vendas, em tempo real, no celular, computador ou tablet."
                          )}
                        </p>
                      </Description>
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>{t("Reduza custos operacionais.")}</p>
                      </Description>
                      <Description>
                        <di>
                          <FaCheck color="#ABE613" />
                        </di>
                        <p>
                          {t("Aumente a eficiência de atendimento do seu PDV.")}
                        </p>
                      </Description>
                    </div>
                  </div>
                </CardB>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastPlan openToast={open}  setOpenToat={setOpen} openModal={openModal} />
    </Container>
  );
}
