import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { ButtonCallToAction, Container } from "./styles";
import { ColoredCard, Button, ColoredCard2 } from "../../shared_components";
import { LoginImage } from "../../assets/images";
import Machines from "../../assets/images/maquinas.png";
import MachinesMobile from "../../assets/images/maquinas-mobile.png";
import BannerPeople from "../../assets/images/pessoa-banner.png";
import Ramos from "../../assets/images/ramos.png";
import MaquinaNeutra from "../../assets/images/faixa4machine.png";
import Banner from "../../assets/images/banner.png";
import Vimeo from "@u-wave/react-vimeo";
import { createGaEvent } from "../../services/analytics";
import { Plans, SubscribeForm } from "./components";
import { useTranslation } from "react-i18next";
import { GrClose } from "react-icons/gr";
import { useWindowSize } from "../../custom_hooks";
function Main() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const navigate = (id, val = 0) => {
    const offSetID = document.getElementById(id)
      ? document.getElementById(id).offsetTop - val
      : 0;
    createGaEvent("AnchorTag", id);
    goTop(offSetID);
  };
  const goTop = (top) => {
    try {
      window.scroll({
        top: top || 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  };
  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <Container>
      <div className="content_container">
        <div className="inner_page">
          <div className="animate__animated animate__bounceInDown">
            <h1>
              O POS CONTROLE
              <br />é um <span>PDV Móvel</span>
              <br />
            </h1>
            <h3>
              que facilita a operação e reduz custos.
              <br />
              Dispensa todos os equipamentos do seu <br />
              ponto de venda, emite <strong>NFC-e</strong>,
              <strong> Fichas</strong>,
              <br />
              <strong>SAT</strong>, <strong>Pedidos/Senhas</strong> ou <strong>Ingressos</strong>.
              <br />
              Gera relatórios e gráficos online
              <br />
              das suas vendas. Integra-se ao seu ERP.
            </h3>
            <Button
              styles={{
                color: "var(--color-primary)",
                background: "white",
                marginBottom: 20,
              }}
              onClick={() => {
                // createGaEvent("link", "Revenda");
                // window.open("http://revenda.poscontrole.com.br");
                // history.push("/revenda");
                navigate("o_que_e", 90);
              }}
              text={"CONHEÇA O SISTEMA"}
            />
          </div>

          <img
            className="phone animate__animated animate__bounceInRight"
            src={LoginImage}
            alt="Brandless Pos with POS CONTROLE"
          />
        </div>

        <div id="o_que_e" className="inner_page_2">
          {/* <h1>Sistema Multiplataforma</h1> */}
          <h1>
            Nosso sistema está homologado nas principais adquirências e
            <br /> equipamentos do mercado
          </h1>

          <div className="machines_container">
            <img
              src={width > 1250 ? Machines : MachinesMobile}
              alt="Maquinas De cartão"
            />

            <ButtonCallToAction
              style={{ marginTop: "30px" }}
              onClick={() => navigate("cadastre-se", 90)}
            >
              Contratar agora
            </ButtonCallToAction>
          </div>
        </div>

        <div className="inner_page_3">
          <h1>Funcionalidades</h1>
          <div className="card">
            <ColoredCard
              icon={"laptop-outline"}
              title={
                "Cadastre grupos, produtos e usuários em nosso portal web."
              }
            />
            <ColoredCard
              icon={"ticket-outline"}
              title={
                "Realize vendas e emita NFC-e, SAT, Fichas, Pedido/Senha e ingressos."
              }
            />
            <ColoredCard
              icon={"cash-outline"}
              title={"Sangria e Suprimento."}
            />
            <ColoredCard
              icon={"card-outline"}
              title={
                "Receba pagamentos em cartão de crédito/débito, dinheiro ou PIX."
              }
            />
            <ColoredCard
              icon={"phone-portrait-outline"}
              title={
                "Acompanhe suas vendas e gere relatórios pelo computador ou celular."
              }
            />
          </div>
          <ButtonCallToAction
            style={{ marginTop: "30px" }}
            onClick={() => navigate("cadastre-se", 90)}
          >
            Adquirir o sistema
          </ButtonCallToAction>
        </div>

        <div className="inner_page_4">
          <div className="advantages_container">
            <h1>Ideal para</h1>
            {/* <div className="iconed_text">
              <ion-icon name="fast-food-outline" />
              <p>Restaurantes, bares, lanchonetes, etc.</p>
            </div>
            <div className="iconed_text">
              <ion-icon name="bus-outline" />
              <p>Food Trucks</p>
            </div>
            <div className="iconed_text">
              <ion-icon name="basket-outline" />
              <p>Varejo</p>
            </div>
            <div className="iconed_text">
              <ion-icon name="ticket-outline" />
              <p>Shows e Eventos</p>
            </div> */}
            <div className="fichas_container">
              <img src={Ramos} alt="Fichas" />
            </div>
          </div>
          <div className="video">
            <h1>
              Ainda com dúvida de como nosso sistema de PDV móvel pode te
              ajudar? Assista o vídeo demonstrativo abaixo.
            </h1>
            <div>
              <Vimeo video={"https://vimeo.com/808457716"} />
            </div>
          </div>
        </div>
        <ButtonCallToAction onClick={() => navigate("cadastre-se", 90)}>
          Contratar para meu negócio
        </ButtonCallToAction>

        <div id="conheca_sistema" className="inner_page_8">
          <h1>Porque optar pelo nosso sistema?</h1>
          {/* <h2>
            Com nosso sistema você irá emitir NFC-e, Fichas e Pedidos direto do
            seu <span>PDV Móvel</span>.
          </h2> */}
          <div className="inner_page_8_content">
            <div className="inner_page_8_img">
              <img
                src={MaquinaNeutra}
                alt="Máquina de Cartão"
                className="machine__neutra"
              />
              {/* <img
              src={Tickets}
              alt="Impressões de Máquina de Cartão"
              className="tickets__img"
            /> */}
            </div>
            <div className="inner_page_8_list">
              <ul>
                <li>Melhoramos a qualidade do atendimento</li>
                <li>Ampliamos retenção das vendas</li>
                <li>
                  Possibilitamos ampliação e redução dos PDVs em datas de pico
                </li>
                <li>Geramos mobilidade dentro e fora da loja</li>
                <li>Geramos inteligência comercial</li>
                <li>
                  Redução de consumo de energia elétrica e menor lixo eletrônico
                </li>
                <li>Simplificamos o PDV</li>
                <li>Reduzimos custos</li>
              </ul>
            </div>
          </div>
        </div>
        <ButtonCallToAction onClick={() => navigate("cadastre-se", 90)}>
          Adquirir essa solução
        </ButtonCallToAction>
        {/* <div id="como_utilizar" className="inner_page_5">
          <h2>
            Continua na dúvida de como nosso sistema de PDV móvel pode te
            ajudar? Assista o vídeo demonstrativo abaixo e clique em comprar
            agora!
          </h2>
          <div>
            <Vimeo video={"https://vimeo.com/808457716"} />
          </div>
          <ButtonCallToAction
            style={{ marginTop: "30px" }}
            onClick={() => navigate("cadastre-se", 90)}
          >
            Compre Agora
          </ButtonCallToAction>
        </div> */}

        <div className="inner_page_7">
          <h1>Integrações e outros produtos</h1>
          <div className="colored_card_container">
            <ColoredCard2
              subTitle={"Integração com ERP"}
              title={
                <p>
                  Softup, F360º, Waybit, Próton, Domper, Catálogo Delivery,
                  Totali e Omie
                </p>
              }
            />
            <ColoredCard2
              subTitle={"Integração com CRM"}
              title={<p>Eu Falo e Ame</p>}
            />
            <ColoredCard2
              subTitle={"Integração com CASHBACK"}
              title={<p>Eu Falo</p>}
            />
          </div>

          <div className="colored_card_container">
            <ColoredCard2
              subTitle={"KDS COZINHA / BALCÃO"}
              title={
                <p>
                  Acompanhe os pedidos por um tablet na cozinha e em uma
                  TV/Monitor no balcão
                </p>
              }
            />
            <ColoredCard2
              subTitle={"Solução CASHLESS"}
              title={
                <p>Meio de pagamento em cartão/pulseira NFC para eventos</p>
              }
            />
          </div>
        </div>
        <div id="cadastre-se" className="inner_page_6">
          {/* <SubscribeForm /> */}
          <Plans openModal={openModal} />
        </div>

        <div
          className="banner"
          style={{
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 500px",
            height: "500px",
          }}
        >
          <div className="banner__content">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1>
                {t(" Ainda na dúvida sobre qual")} <br />
                <span>{t("plano ideal para seu negócio?")}</span>
              </h1>
              <button onClick={openModal}>
                {t("Falar com nossos especialistas")}
              </button>
            </div>
            <img src={BannerPeople} alt="Banner" />
          </div>
        </div>
      </div>

      <Dialog.Root open={isOpenModal} onOpenChange={setIsOpenModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Dialog.Title className="DialogTitle">
              Preencha o formulário,
              <br /> <span> e entraremos em contato.</span>
            </Dialog.Title>
            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
                border: "solid gray 1px",
                borderRadius: "3px",
                margin: "0 5px",
                padding: "5px 35px",
              }}
            >
              <SubscribeForm />
            </div>
            <Dialog.Close asChild onClick={closeModal}>
              <button className="IconButton" aria-label="Close">
                <GrClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </Container>
  );
}

export default Main;
