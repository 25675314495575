import React from "react";
import { useTranslation } from "react-i18next";
import Machines from "../../assets/images/maquinas.png";
import MachinesMobile from "../../assets/images/maquinas-mobile.png";
import { ButtonCallToAction, Container } from "./styles";
import { ColoredCard, Button, ColoredCard2 } from "../../shared_components";
import { LoginImage } from "../../assets/images";
import Ramos from "../../assets/images/ramos.png";
import Vimeo from "@u-wave/react-vimeo";

import { createGaEvent } from "../../services/analytics";
import FormWhitelabel from "./components/FormWhiteLabel";
import "./components/style.css";
import { useWindowSize } from "../../custom_hooks";
function WhiteLabel() {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const navigate = (id, val = 0) => {
    const offSetID = document.getElementById(id)
      ? document.getElementById(id).offsetTop - val
      : 0;
    createGaEvent("AnchorTag", id);
    goTop(offSetID);
  };
  const goTop = (top) => {
    try {
      window.scroll({
        top: top || 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <Container>
      {/* <Nav /> */}
      <div className="content_container">
        <div className="inner_page">
          <div className="animate__animated animate__bounceInDown">
            <h1>
              SEJA UMA REVENDA
              <br />
              <span>WHITE LABEL</span>
              <br />
            </h1>
            <h3>
              e facilite a operação de seus clientes.
              <br />
              Com o<strong> PDV MOBI</strong> ele irá dispensar todos os <br />
              equipamentos do ponto de venda, emitir <strong>Fichas</strong>, <strong>SAT</strong>
              <br />
              <strong>Pedidos</strong> ou <strong>NFC-e</strong>. Gerar relatórios e gráficos online
              <br />
              das suas vendas. Integrar ao ERP que já utiliza.
            </h3>
            <Button
              styles={{
                color: "var(--color-primary)",
                background: "white",
                marginBottom: 20,
              }}
              onClick={() => {
                // createGaEvent("link", "Revenda");
                // window.open("http://revenda.poscontrole.com.br");
                navigate("o_que_e", 90);
              }}
              text={"CONHEÇA O SISTEMA"}
            />
          </div>

          <img
            className="phone animate__animated animate__bounceInRight"
            src={LoginImage}
            alt="Brandless Pos with POS CONTROLE"
          />
        </div>

        <div id="o_que_e" className="inner_page_2">
          <h1>
            Nosso sistema está homologado nas principais adquirências e
            <br /> equipamentos do mercado
          </h1>

          <div className="machines_container">
          <img
              src={width > 1250 ? Machines : MachinesMobile}
              alt="Maquinas De cartão"
            />
            <ButtonCallToAction
              style={{ marginTop: "30px" }}
              onClick={() => navigate("cadastre-se", 90)}
            >
              Contratar agora
            </ButtonCallToAction>
          </div>
        </div>

        <div className="inner_page_3">
          <h1>Funcionalidades</h1>
          <div className="card">
            <ColoredCard
              icon={"laptop-outline"}
              title={
                "Cadastre grupos, produtos e usuários em nosso portal web."
              }
            />
            <ColoredCard
              icon={"ticket-outline"}
              title={
                "Realize vendas e emita NFC-e, SAT, Fichas, Pedido/Senha e ingressos."
              }
            />
            <ColoredCard
              icon={"cash-outline"}
              title={"Sangria e Suprimento."}
            />
            <ColoredCard
              icon={"card-outline"}
              title={
                "Receba pagamentos em cartão de crédito/débito, dinheiro ou PIX."
              }
            />
            <ColoredCard
              icon={"phone-portrait-outline"}
              title={
                "Acompanhe suas vendas e gere relatórios pelo computador ou celular."
              }
            />
          </div>
          <ButtonCallToAction
            style={{ marginTop: "30px" }}
            onClick={() => navigate("cadastre-se", 90)}
          >
            Adquirir o sistema
          </ButtonCallToAction>
        </div>
        <div className="inner_page_4">
          <div className="advantages_container">
            <h1>Ideal para</h1>
            {/* <div className="iconed_text">
              <ion-icon name="fast-food-outline" />
              <p>Restaurantes, bares, lanchonetes, etc.</p>
            </div>
            <div className="iconed_text">
              <ion-icon name="bus-outline" />
              <p>Food Trucks</p>
            </div>
            <div className="iconed_text">
              <ion-icon name="basket-outline" />
              <p>Varejo</p>
            </div>
            <div className="iconed_text">
              <ion-icon name="ticket-outline" />
              <p>Shows e Eventos</p>
            </div> */}
            <div className="fichas_container">
              <img src={Ramos} alt="Fichas" />
            </div>
          </div>
          <div className="video">
            <h1>
              Ainda com dúvida de como nosso sistema de PDV móvel pode te
              ajudar? Assista o vídeo demonstrativo abaixo.
            </h1>
            <div>
              <Vimeo video={"https://player.vimeo.com/video/720285649"} />
            </div>
          </div>
        </div>
        <ButtonCallToAction onClick={() => navigate("cadastre-se", 90)}>
          Inscreva-se agora
        </ButtonCallToAction>

        {/* <div id="como_utilizar" className="inner_page_5">
          <div className="info_container">
            <h1>Como Utilizar?</h1>
            <h2>Veja como é simples o seu PDV Móvel</h2>
          </div>
          <div>
            <Vimeo video={"https://player.vimeo.com/video/720285649"} />
          </div>
          <ButtonCallToAction
            style={{ marginTop: "30px" }}
            onClick={() => navigate("cadastre-se", 90)}
          >
            Inscreva-se agora
          </ButtonCallToAction>
        </div> */}

        <div className="inner_page_7">
          <h1>Integrações e outros produtos</h1>
          <div className="colored_card_container">
            <ColoredCard2
              subTitle={"Integração com ERP"}
              title={
                <p>
                  Softup, F360º, Waybit, Próton, Domper, Catálogo Delivery,
                  Totali e Omie
                </p>
              }
            />
            <ColoredCard2
              subTitle={"Integração com CRM"}
              title={<p>Eu Falo e Ame</p>}
            />
            <ColoredCard2
              subTitle={"Integração com CASHBACK"}
              title={<p>Eu Falo</p>}
            />
            <ColoredCard2
              subTitle={"KDS COZINHA / BALCÃO"}
              title={
                <p>
                  Acompanhe os pedidos por um tablet na cozinha e em uma
                  TV/Monitor no balcão
                </p>
              }
            />
          </div>

          <div className="colored_card_container">
            <ColoredCard2
              subTitle={"Solução CASHLESS"}
              title={
                <p>Meio de pagamento em cartão/pulseira NFC para eventos</p>
              }
            />
            <ColoredCard2
              subTitle={"TEF WEB"}
              title={<p>Faça requisições de captura de meios de pagamentos</p>}
            />
            <ColoredCard2
              subTitle={"MULTIPV"}
              title={
                <p>
                  Receba débito/crédito para vários CNPJs em uma só maquininha
                </p>
              }
            />
          </div>
        </div>
        <div id="cadastre-se" className="inner_page_6">
          <FormWhitelabel description />
        </div>
      </div>
    </Container>
  );
}

export default WhiteLabel;
