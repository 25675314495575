import React, { useState, useEffect } from "react";
import { Container } from "./styles";

export default function FormWhitelabel({ description, styleProps }) {
  const [companyDOC, setCompanyDOC] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");

  const [infoMessages, setInfoMessages] = useState(() => {
    return {
      success: {
        state: false,
        message: "Pronto, agora é só conferir o seu e-mail.",
      },
      error: {
        state: false,
        message:
          "Ops, ocorreu um erro. Verifique seus dados ou tente novamente mais tarde.",
      },
    };
  });

//   const submitForm = (e) => {
//     e.persist();
//     e.preventDefault();

//     const name = e.target["company_name"].value;
//     const email = e.target["company_email"].value;
//     const emailRegex = /\S+@\S+\.\S+/;
//     let error = false;

//     if (!emailRegex.test(email)) {
//       setInfoMessages((m) => {
//         return {
//           ...m,
//           email: {
//             status: true,
//             message: "Por favor insira um e-mail válido.",
//           },
//         };
//       });
//       error = true;
//     }

//     if (name.length < 2) {
//       setInfoMessages((m) => {
//         return {
//           ...m,
//           name: {
//             status: true,
//             message: "Por favor insira um nome de estabelecimento válido.",
//           },
//         };
//       });
//       error = true;
//     }

//     if (!error) {
//       registerAPI
//         .createUser(name, email, companyPhone, companyDOC)
//         .then((res) => {
//           if (res.data.ret && res.data.ret.status === "erro") {
//             setInfoMessages((m) => {
//               return {
//                 success: { ...m.success, status: false },
//                 error: { message: res.data.ret.detalhe[0].msg, status: true },
//               };
//             });
//           } else {
//             setInfoMessages((m) => {
//               return {
//                 success: { ...m.success, status: true },
//                 error: { ...m.error, status: false },
//               };
//             });
//           }
//         });
//     }
//   };
  useEffect(() => {
    if (window.RDStationForms) {
      new window.RDStationForms(
        "form-white-label-site-395bf0886fcf29fe53e6",
        "UA-8911463-21"
      ).createForm();
    }
  }, []);

  return (
    <div id="cadastro">
      <Container id="form_cadastro">
        {/* <div className="blue_outlined"></div>
        <div className="blue_filled"></div> */}
        <div className="form_container">
          {description && (
            <div className="main_info">
              <h2>
                Crie sua loja preenchendo os dados ao lado.
                <br />
                <br />
                Você receberá um e-mail e senha para cadastrar os produtos no
                portal.
              </h2>
            </div>
          )}
          <div style={{ width: '700px'}} role="main" id="form-white-label-site-395bf0886fcf29fe53e6"></div>
        </div>
      </Container>
    </div>
  );
}
