import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  margin: 100px auto 0px auto;
  justify-content: center;
  align-items: center;
 
  .plans {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 670px;
    flex-wrap: wrap;
    gap: 40px;
    @media (max-width: 1049px) {
      margin-bottom: 200px;
    }
  }

 

  .plan__a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    
  }
  .change__quatity {
    height: 100px;
    .controls__quatity {
      display: flex;
      margin: 10px 0px 0px 0px;
      justify-content: center;
      align-items: center;
    }
    button {
      border: none;
      border-radius: 10px;
      width: 35px;
      height: 30px;
      cursor: pointer;
    }
  }

  .plan__b {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }
`;

export const TextTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-family: var(--font-family-primary);
    margin-bottom: 20px;
  }
  h2 {
    font-family: var(--font-family-secondary);
    font-weight: 400;
  }
`;

export const CardA = styled.div`
  background: linear-gradient(0.6turn, #f8ae55, #f58747);
  height: 100%;
  width: 500px;
  border-radius: 20px;
  box-shadow: -5px 7px 10px #e0e2d2;
  padding: 15px 10px 10px 10px;
  /* @media (max-width: 560px) {
    width: 440px;
    padding: 15px 7px 10px 7px;
  } */
  @media (max-width: 664px) {
    width: 290px;
  }
  .plan_a_price {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin-bottom: 12px;
    
    @media (max-width: 664px) {
      align-items: start;
      flex-direction: column;
      height: auto;
      width: 40%;
      margin: 5px;
  }

    

    .select_qnt {
      display: flex;
      flex-direction: column;
      width: 180px ;
      gap: 10px;
      .controls__quatity {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
      }
      p {
        font-weight: 400;
        font-size: 12px ;
        color: #FFFFFF;
      }
    }
    button {
      border: none;
      border-radius: 10px;
      width: 35px;
      height: 30px;
      cursor: pointer;
    }
  }

  .list__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0px;
  }
  .content__card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin: 10px auto;
    width: 450px;
    font-weight: 900;
    
    h2 {
      color: white;
      font-size: 20px;
      margin: 5px 0px;
    }

    h1 {
      color: white;
      display: flex;
      font-size: 50px;
      span {
        font-weight: bold;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        width: 45px;
        margin-top: 10px;
        margin-left: 2px;
      }

      p {
        font-size: 10px;
        font-weight: 400;
      }
    }
  }

  button {
    width: 100%;
    border-radius: 6px;
    height: 35px;
    margin: 5px auto;
    border: none;
    background: #fff;
    color: #f58747;
    font-size: 18px;
    @media (max-width: 664px) {
    width: 50%;
    margin: 10px 25px;
    font-size: 15px;
  }
    :hover {
      opacity: 0.9;
      scale: 1.05;
      transition: all 0.5s;
      cursor: pointer;
    }
  }
`;

export const CardB = styled.div`
  background: linear-gradient(0.6turn, #f8ae55, #f58747);
  height: 100%;
  width: 500px;
  border-radius: 20px;
  box-shadow: -5px 10px 6px #e0e2d2;
  padding: 15px 10px 10px 10px;
  @media (max-width: 664px) {
    width: 290px;
  }

  .plan_a_price {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin-bottom: 12px;
    @media (max-width: 664px) {
      align-items: start;
      flex-direction: column;
      height: auto;
      width: 40%;
      margin: 5px;
  }
  .select_qnt {
      display: flex;
      flex-direction: column;
      width: 180px ;
      gap: 10px;
      .controls__quatity {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
      }
      p {
        font-weight: 400;
        font-size: 12px ;
        color: #FFFFFF;
      }
    }
    button {
      border: none;
      border-radius: 10px;
      width: 35px;
      height: 30px;
      cursor: pointer;
    }
  }
  .list__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin: 1px 0px; */
  }
  .content__card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin: 10px auto;
    width: 450px;
    font-weight: 900;
    h2 {
      color: white;
      font-size: 20px;
      margin: 5px 0px;
    }

    h1 {
      color: white;
      display: flex;
      font-size: 50px;
      span {
        font-weight: bold;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        width: 45px;
        margin-top: 10px;
        margin-left: 2px;
        justify-content: start;
        align-items: flex-start;
      }

      p {
        font-size: 10px;
        font-weight: 400;
      }
    }
  }

  button {
    width: 100%;
    border-radius: 6px;
    height: 35px;
    margin: 5px auto;
    border: none;
    background: #fff;
    color: #f58747;
    font-size: 18px;
    @media (max-width: 664px) {
      width: 50%;
      margin: 10px 25px;
      font-size: 15px;
  }
    :hover {
      opacity: 0.9;
      scale: 1.05;
      transition: all 0.5s;
      cursor: pointer;
    }
  }
`;

export const Line = styled.div`
  width: 55em;
  margin: 20px auto;
  height: 0px;
  border-bottom: 3px solid #f1f1f1;
`;

export const Description = styled.div`
  display: flex;
  width: 95%;
  height: auto;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
  margin: 3px 0px;
  @media (max-width: 664px) {
    width: 60%;
  }
  p {
    font-size: 17px;
    color: #fff;
    font-weight: 400;
    width: auto;
    text-align: left;
    margin: 8px 0px 8px 5px;
  }
  svg {
    font-size: 22px;
  }
`;
export const Modal = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};

  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3399;
  background: rgb(0, 0, 0, 0.3);

  .modal_content {
    margin: 50px;
  }
`;


export const ImplatatioValue = styled.p`
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: start;
`