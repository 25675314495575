import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { FaRegCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { createUser } from "../../services/register";
import { Container } from "./style";
import Cover from "../../assets/images/bg-questionario.png";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog as DialogPrime } from "primereact/dialog";
import { Button } from "primereact/button";
import { sendConversion } from "../../services/RDStation";
export default function Checkout() {
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [telefone, setTelefone] = useState("");
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    cnpj: false,
    telefone: false,
  });
  const [infoMessage, setInfoMessage] = useState("");
  const location = useLocation();
  let history = useHistory();
  
  const onSubmit = async () => {
    try {
      // const emailRegex = /\S+@\S+\.\S+/;

      // if (!emailRegex.test(email)) {

      // }
      const qntPOS = location.state.qntPos;
      const price = location.state.price;
      if (nome.trim() === "") {
        setErrors((prevState) => ({ ...prevState, name: true }));
        return;
      }
      if (email.trim() === "") {
        setErrors((prevState) => ({ ...prevState, email: true }));
        return;
      }
      if (cnpj.trim() === "") {
        setErrors((prevState) => ({ ...prevState, cnpj: true }));
        return;
      }
      if (telefone.trim() === "") {
        setErrors((prevState) => ({ ...prevState, telefone: true }));
        return;
      }
      setIsLoading(true);
      createUser(nome, email, telefone, cnpj, qntPOS, price)
        .then(async (res) => {
          await sendConversion(email,nome,telefone, cnpj, qntPOS)
          if (res.data.ret && res.data.ret.status === "erro") {
            setInfoMessage(res.data.ret.detalhe[0].msg);
            setIsOpenErrorModal(true);
          } else {
            setIsOpenSuccessModal(true);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const close = () => {
    setIsOpenSuccessModal(false);
    history.push("/");
  };
  const closeModalError = () => {
    setIsOpenErrorModal(false);
  };
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Entendi"
          icon="pi pi-check"
          onClick={() => history.push("/")}
          autoFocus
          style={{ background: "#f8ae55" }}
        />
      </div>
    );
  };
  const renderFooterError = () => {
    return (
      <div>
        <Button
          label="Tentar novamente"
          icon="pi pi-check"
          onClick={() => setIsOpenErrorModal(false)}
          autoFocus
          style={{ background: "#f8ae55" }}
        />
      </div>
    );
  };
  return (
    <Container>
      <div style={{ width: '100%', display:'flex', flexDirection: 'column', alignItems: 'end'}}>
      <button onClick={() => history.push("/")}>
        <ion-icon name={"close-outline"} />
      </button>
      <div className="card">
        <img className="card__image" src={Cover} alt="" />
        <div className="card__form">
          <div className="content__form">
            <h1>
              Crie sua loja <br /> preenchendo <br /> os dados abaixo:
            </h1>
            <div className="form__group">
              <InputText
                placeholder="Nome"
                value={nome}
                onChange={(e) => {
                  setErrors((prevState) => ({ ...prevState, name: false }));
                  setNome(e.target.value);
                }}
              />

              {errors.name && (
                <Message severity="error" text="Preencha o nome!" />
              )}
            </div>
            <div className="form__group">
              <InputText
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => {
                  setErrors((prevState) => ({ ...prevState, email: false }));
                  setEmail(e.target.value);
                }}
              />
              {errors.email && (
                <Message severity="error" text="Preencha o E-mail!" />
              )}
            </div>
            <div className="form__group">
              <InputText
                placeholder="CNPJ/CPF"
                value={cnpj}
                maxLength={18}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/\D/g, "");
                  if (value.length >= 12) {
                    value = value
                      .replace(/\D+/g, "") // não deixa ser digitado nenhuma letra
                      .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
                      .replace(/(\d{3})(\d)/, "$1.$2")
                      .replace(/(\d{3})(\d)/, "$1/$2") // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
                      .replace(/(\d{4})(\d)/, "$1-$2")
                      .replace(/(-\d{2})\d+?$/, "$1");
                  } else {
                    value = value
                      .replace(/\D+/g, "") // não deixa ser digitado nenhuma letra
                      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
                      .replace(/(\d{3})(\d)/, "$1.$2")
                      .replace(/(\d{3})(\d)/, "$1-$2");
                    // .replace(/(-\d{2})\d+?/, '$1')
                  }
                  setErrors((prevState) => ({ ...prevState, cnpj: false }));
                  setCnpj(value);
                }}
              />

              {errors.cnpj && (
                <Message severity="error" text="Preencha o CNPJ/CPF!" />
              )}
            </div>
            <div className="form__group">
              <InputMask
                placeholder="Telefone"
                mask={"(99)99999-9999"}
                type="text"
                value={telefone}
                onChange={(e) => {
                  setErrors((prevState) => ({ ...prevState, telefone: false }));
                  setTelefone(e.target.value);
                }}
              />
              {errors.telefone && (
                <Message severity="error" text="Preencha o telefone!" />
              )}
            </div>
            <button disabled={isLoading} onClick={onSubmit}>
              {isLoading ? (
                <ProgressSpinner
                  style={{ width: "50px", height: "50px" }}
                  strokeWidth="3"
                  animationDuration=".5s"
                />
              ) : (
                "Finalizar"
              )}
            </button>
          </div>
        </div>
      </div>
      </div>
      <DialogPrime
        header="Error"
        visible={isOpenErrorModal}
        style={{ width: "70vw" }}
        onHide={() => closeModalError()}
        footer={renderFooterError}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: 20,
          }}
        >
          <FaExclamationCircle size={55} color="red" />
          <p style={{ fontSize: "1.4em" }}>{infoMessage}</p>
        </div>
      </DialogPrime>
      <DialogPrime
        header="Sucesso"
        visible={isOpenSuccessModal}
        style={{ width: "70vw" }}
        onHide={() => close()}
        footer={renderFooter}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: 20,
          }}
        >
          <FaRegCheckCircle size={55} color="#8fbe00" />
          <p style={{ fontSize: "1.4em" }}>
            Pronto, agora é só conferir o seu e-mail.
          </p>
        </div>
      </DialogPrime>
    </Container>
  );
}
