import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7%;
  background: white;
  .hiperlink {
    font-weight: 600;
    margin: 0 20px 0;
    text-decoration: none;
    color: black;

    &:hover {
      color: var(--color-secondary);
      border-bottom: 2px solid var(--color-secondary);
      transition: 0.1s;
      cursor: pointer;
    }
  }

  .highlighted_link {
    color: var(--color-accent);
  }

  img {
    max-height: 30px;
    cursor: pointer;
  }
  .top_nav_title {
    ion-icon {
      font-size: 24px;
      cursor: pointer;
    }
  }
  .dropdown-Link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;

    svg {
      margin-top: 3px;
    }
  }
  .dropdown-menu {
    flex-direction: column;
    gap: 2px;
    padding: 12px 0;
    justify-content: start;
    align-items: start;
    background: #ffffff;
    width: 200px;
    height: 140px;
    border: 2px solid #e9e9e9;
    border-radius: 8px;
    position: absolute;
    top: 56px;
    margin-left: 12px;
    z-index: 3000;
  }
`;

export const DrawerContainer = styled.div`
  background: white;
  .hiperlink {
    font-weight: 600;
    margin: 10px 10px 5px;
    text-decoration: none;
    color: black;

    &:hover {
      color: var(--color-secondary);
      border-bottom: 2px solid var(--color-secondary);
      transition: 0.1s;
      cursor: pointer;
    }
  }

  .lateral_nav {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 100vh;
    transition: 0.3s;
    position: fixed;
    right: -277px;
    top: 90px;
    width: 237px;
    z-index: 2021;
  }
  .lateral_nav.active {
    opacity: 1;
    transition: 0.3s;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    right: 0px;
    top: 90px;
    width: 237px;
    z-index: 2021;
    background: white;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  .options_container {
    display: flex;
    flex-direction: column;
  }
  .hiperlink {
    padding: 5px;
  }

  .dropdown-menu-mobile {
    flex-direction: column;
    gap: 2px;
    padding: 12px 0;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    width: 200px;
    height: 210px;
    z-index: 3001;
    margin: 0 auto;
    transition: all 2s ease-out;  
  }
`;
