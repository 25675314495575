import axios from "axios";

export const sendConversion = async (email,nome, telefone, cnpj, qntPOS) => {
  try {
    const options = {
      method: "POST",
      url: "https://api.rd.services/platform/conversions?api_key=CAbibsuKidCbYowQNFNhzyFsAUDpuGgdDBzV",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        event_type: "CONVERSION",
        event_family: "CDP",
        payload: {
          conversion_identifier: "Conversão Site Compra",
          email: email,
          name: nome,
          personal_phone: telefone,
          cf_cnpj_cpf: cnpj,
          cf_quantidade_pos: `${qntPOS}`
        },
      },
    };
    await axios.request(options);
  } catch (error) {
    console.log(error);
  }
};
