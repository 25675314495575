import * as React from "react";
import * as Toast from "@radix-ui/react-toast";
import "./styles.js";
import { ContainerToast } from "./styles.js";

const ToastPlan = ({ openToast, setOpenToat, openModal }) => {
  //   const [open, setOpen] = React.useState(true);
  return (
    <ContainerToast>
      <Toast.Provider swipeDirection="right">
        <Toast.Root
          className="ToastRoot"
          open={openToast}
          onOpenChange={setOpenToat}
        >
          {/* <Toast.Title className="ToastTitle">Scheduled: Catch up</Toast.Title> */}
          <Toast.Description asChild>
            <p>Acima dessa quantidade fale com nossos especialista.</p>
          </Toast.Description>
          <Toast.Action
            className="ToastAction"
            asChild
            altText="Goto schedule to undo"
          >
            <button className="Button" onClick={openModal}>
              Abrir formulário
            </button>
          </Toast.Action>
        </Toast.Root>
        <Toast.Viewport className="ToastViewport" />
      </Toast.Provider>
    </ContainerToast>
  );
};

export default ToastPlan;
