import styled from 'styled-components'


export const Container = styled.div`
            
        
        border-radius: 8px;
        margin: 1% 1% 1% 0;

        &:last-child {
            margin: 1% 0;
        }

        max-width: 300px;
        color: var(--color-light);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        overflow: hidden;
        box-shadow: 2px 6px 20px 1px rgba(0, 0, 0, 0.16);

        &:last-child {
            margin-right: 0;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            font-family: var(--font-family-secondary);
            color: black;
        }

        h5 {
            font-size: 14px;
            margin: 10px 0;
            color: white;
        }

        ion-icon {
            color: white;
            margin: 10px 0;
            font-size: 30px;
        }
        .promo_name {
            display: flex;
            padding: 40px 20px;
            width: 100%;
            align-items: center;    
            background: white;
            justify-content: center;
            height: 100%;
        }

        .promo_price {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: linear-gradient(270deg, var(--color-accent), var(--color-secondary-accent));
            
      
        }
    
`