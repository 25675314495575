import React from 'react'
import { Container } from './styles'

export default function ColoredCard2({ title, className,  subTitle, icon, onClick = () => {} }) {
    return (
        <Container className={className} onClick={onClick}>
            <div className='promo_price'>
                {subTitle && <h4>{subTitle}</h4>}
                {icon && <ion-icon name={icon} />}
            </div>
            {/* <div className='promo_name'>
                {title}
            </div> */}


        </Container>
    )
}