import styled from "styled-components";

export const Container = styled.div`
  background: #f3f3f3;
  min-height: 100vh;
  .inner_page {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap-reverse;
    transition: margin-right 2s;
    background: linear-gradient(
      270deg,
      var(--color-primary),
      var(--color-secondary)
    );

    padding: 0 5% 0;
    text-align: left;
    margin-bottom: 95px;

    h1 {
      font-family: var(--font-family-primary);
      font-size: 3.5vmax;
      color: white;
      margin-bottom: 20px;
    }

    h3 {
      font-family: var(--font-family-secondary);
      font-weight: 500;
      color: white;
      margin-bottom: 40px;
      line-height: 27px;
    }

    span {
      color: var(--color-accent);
    }

    img {
      max-width: 40%;
      margin: 40px 0;
      min-width: 300px;
    }
  }
  .banner {
    width: 100%;
    margin-top: 100px;

    .banner__content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;

      @media (max-width: 1005px) {
        margin: 150px auto;
        padding-top: 150px;
        font-size: 14px;
      }

      h1 {
        color: #fff;
        font-weight: 400;
        text-align: start;
        margin-bottom: 20px;
        span {
          font-weight: 900;
        }
      }

      button {
        border: none;
        background: #fff;
        color: #f58747;
        width: 370px;
        height: 50px;
        font-size: 24px;
        font-weight: 400;
        border-radius: 6px;
        padding: 2px;
        @media (max-width: 1007px) {
          margin: 0 auto;
        }
        :hover {
          opacity: 0.9;
          scale: 1.1;
          transition: all 0.5s;
          cursor: pointer;
        }
      }

      img {
        width: auto;
        height: 500px;

        @media (max-width: 1007px) {
          display: none;
        }
      }
    }
  }
  .inner_page_2 {
    h1 {
      font-family: var(--font-family-primary);
      margin-bottom: 20px;
    }

    h2 {
      font-family: var(--font-family-secondary);
      font-weight: 400;
    }

    .machines_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      max-height: 20%;
      margin: 42px auto;
      img {
        width: 1000px;
        height: 450px;
        @media (max-width: 1250px) {
          width: 95%;
          height: auto;
        }
      }
    }
  }

  .inner_page_3 {
    h1 {
      font-family: var(--font-family-primary);
      margin-bottom: 10px;
    }
    
    .card {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 8px;
      margin: 70px 8%;
      @media (max-width: 840px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 8px;
      }
    }
  }

  .inner_page_4 {
    padding: 90px 5%;
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap ; */
    display: grid;
    grid-template-columns: 1fr 1fr ;
    @media (max-width: 1445px) {
        grid-template-columns: 1fr;
        grid-row-gap: 38px;
      }
    h1 {
      font-family: var(--font-family-primary);
      margin: 15px auto;
    }
    .video {
      iframe {
      width: 1000px;
      height: 500px;
      max-width: 100%;
      margin-top: 25px;
    }
    }
    .advantages_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: var(--font-family-secondary);
      
      .iconed_text {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        ion-icon {
          font-size: 52px;
          color: gray;
          margin-right: 40px;
        }
      }
    }

    .fichas_container {
      img {
        max-width: 70%;
        margin-top: 85px;
        @media (max-width: 1005px) {
          margin-top: -10px;
          max-width: 90%;
        }
      }
    }
  }

  .inner_page_5 {
    padding: 90px 5%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    h2 {
      font-family: var(--font-family-secondary);
      font-weight: 400;
      margin-bottom: 50px;
    }
    .info_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: var(--font-family-secondary);

      h1 {
        margin-bottom: 20px;
      }

      span {
        font-weight: 600;
        color: var(--color-accent);
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
        &:active {
          opacity: 0.5;
          transition: 0.3s;
        }
      }
    }

    iframe {
      width: 1000px;
      height: 500px;
      max-width: 100%;
    }
  }

  .inner_page_7 {
    h1 {
      font-family: var(--font-family-primary);
      font-weight: 600;
      margin-bottom: 40px;

      @media screen and (max-width: 599px) {
        font-size: 24px;
        line-height: 24px !important;
        padding: 0 20px;
      }
    }

    .colored_card_container {
      padding: 0 0 20px;
      display: flex;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
    }

    .hovered {
      &:hover {
        cursor: pointer;
        filter: brightness(95%);
        transition: 0.2s;
      }

      &:active {
        filter: brightness(90%);
        transition: 0.2s;
      }
    }

    .hiperlink {
      text-decoration: underline;
      color: var(--color-accent);
      font-weight: bold;
    }
  }
  .inner_page_8 {
    display: flex;
    flex-direction: column ;
    h1 {
      font-family: var(--font-family-primary);
      margin-bottom: 20px;
    }
    h2 {
      font-family: var(--font-family-secondary);
      font-weight: 400;
    }
    span {
      color: var(--color-accent);
      font-weight: bold;
    }
    .inner_page_8_content {
      display: flex ;
      justify-content: center ;
      align-items: center;
      flex-wrap: wrap;

      .inner_page_8_img {
        display: flex;
        flex-wrap: wrap;
        margin: 85px 50px;
        width: auto ;
        height: auto;
        justify-content: center;
        align-items: center;
        .machine__neutra {
          width: auto;
          height: 390px;
        }
      }

    .inner_page_8_list{
      text-align: left ;

      li {
        margin: 10px 0 ;
        font-size: 22px;
        color: #333333;
        @media (max-width: 1005px) {
            font-size: 14px ;
        }
      }
    }

    }
  }
`;

export const ButtonCallToAction = styled.button`
  width: auto;
  padding: 0 10px;
  height: 3rem;
  border: none;
  background: linear-gradient(
    270deg,
    var(--color-accent),
    var(--color-secondary-accent)
  );
  border-radius: 20px;
  color: #fff;
  font-size: 1.7rem;
  margin: 0 auto;
  margin-bottom: 80px ;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    scale: 110%;
    transition: all 0.5s ease-in-out;
  }
`;
