import styled from 'styled-components'

export const Container = styled.div`
    font-family: var(--font-family-secondary);
    text-align: center;
    
    .form_container {
        display: flex;
        align-items: center;
        text-align: left;
        flex-wrap: wrap;
        background-color: black;
        .button_container {
            display: flex;
            justify-content: flex-end;
            margin: 20px 0 0;
            button {
                margin: 0;
            }
        }

        @media screen and (max-width: 400px) {
            .main_info {
                padding: 50px 20px;
            }
        }

        @media screen and (min-width: 401px) {
            .main_info {
                padding: 50px;
            }
        }

        .main_info {
            flex:1;
            align-items: center;
            min-width: 300px;
            h2 {
                font-size: 18px;
            }
        }

        h1 {
            margin-bottom: 20px;
        }
        
        p {
            font-size: 14px;
        }

        .double_input_container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .single_input_container {
                width: 48%;
            }
        }
    }

    .blue_outlined{
        position: absolute;
        bottom: -200px;
        left: -100px;
        border-radius: 125px;
        transform: rotate(230deg);
        width: 125px;
        height: 400px;
        z-index:1;
        border: 2px solid var(--color-darkBlue);
    }

    .blue_filled{
        position: absolute;
        bottom: -250px;
        left: -60px;
        border-radius: 125px;
        transform: rotate(230deg);
        width: 125px;
        height: 400px;
        z-index: 1;
        background: var(--color-lightBlue);
        opacity: 0.2;
    }



    @media screen and (max-width: 599px) {
        h1 {
            font-size: 24px;
            line-height: 24px !important;
            padding: 0 20px;
        }
        h2 {
            font-size: 18px;
            line-height: 22px !important;
        }
    
    }

`

