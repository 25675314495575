import React, { useEffect, useState, useRef } from "react";
import { Container, DrawerContainer } from "./styles";
import { Logo } from "../../assets/images";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { useWindowSize } from "../../custom_hooks";
import Button from "../button";
import { createGaEvent } from "../../services/analytics";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { I18n } from "../I18n";
function Nav() {
  const [isShown, setIsShown] = useState(false);
  const dropRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const size = useWindowSize();

  const isShownLinkRevenda = location.pathname === "/revenda";
  const isShownHeader = location.pathname === "/checkout";

  const navigate = (id, val = 0) => {
    const offSetID = document.getElementById(id)
      ? document.getElementById(id).offsetTop - val
      : 0;
    createGaEvent("AnchorTag", id);
    goTop(offSetID);
  };

  // const handleActiveAnchor = useCallback(() => {
  //     let screenTop = window.scrollY
  //     const anchors = [{ id: 'o_que_e', plus: 120 }, { id: 'como_utilizar', plus: 400 }, { id: 'cadastre-se', plus: 300 }]
  //     let lastId = history.location.pathname.substring(1)
  //     for (let position in anchors) {
  //         if (document.getElementById(anchors[position].id)) {
  //             if (document.getElementById(anchors[position].id).offsetTop - anchors[position].plus < screenTop) {
  //                 lastId = anchors[position].id
  //             }
  //         }
  //     }
  //     history.push(lastId)
  // }, [history])

  // useEffect(() => {
  //     window.addEventListener('scroll', handleActiveAnchor)
  //     return () => window.removeEventListener('scroll', handleActiveAnchor)
  // }, [handleActiveAnchor])

  useEffect(() => {
    window.onscroll = function () {
      const nav = document.getElementById("top_nav");
      if (nav) {
        let sticky = nav.offsetTop;
        if (window.pageYOffset > sticky) {
          nav.classList.add("sticky");
        } else {
          nav.classList.remove("sticky");
        }
      }
    };
  }, []);
  useEffect(() => {
    const checkClickedOutside = (e) => {
      if (isShown && dropRef.current && !dropRef.current.contains(e.target)) {
        setIsShown(false);
      }
    };
    document.addEventListener("click", checkClickedOutside);

    return () => {
      document.removeEventListener("click", checkClickedOutside);
    };
  }, [isShown]);

  const toggleIsShow = () => setIsShown((oldValue) => !oldValue);

  const closeNav = () => {
    const lateralNav = document.getElementById("lateral_nav");
    const lateralNavContainer = document.getElementById(
      "lateral_nav_background"
    );
    lateralNav.classList.remove("active");
    lateralNavContainer.classList.remove("active_lnav_background");
  };

  const toggleLateralNav = () => {
    const lateralNav = document.getElementById("lateral_nav");
    const lateralNavContainer = document.getElementById(
      "lateral_nav_background"
    );
    if (!lateralNav.classList.contains("active")) {
      lateralNav.classList.add("active");
      lateralNavContainer.classList.add("active_lnav_background");
    } else {
      lateralNav.classList.remove("active");
      lateralNavContainer.classList.remove("active_lnav_background");
    }
  };

  const goTop = (top) => {
    try {
      window.scroll({
        top: top || 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  };

  return (
    !isShownHeader ? (
      <div>
        <Container id="top_nav">
          <img onClick={() => history.push("/")} alt="Logo" src={Logo} />

          {size.width < 1050 ? (
            <div onClick={() => toggleLateralNav()} className="top_nav_title">
              <ion-icon name="menu-outline" />
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <I18n /> */}
              <p
                className="hiperlink"
                onClick={() =>
                  window.open(
                    "https://indique-e-ganhe.poscontrole.com.br?bl_ref=L1Z50"
                  )
                }
              >
                Indique e Ganhe
              </p>
              {!isShownLinkRevenda ? (
                <p
                  className="hiperlink"
                  onClick={() => {
                    createGaEvent("link", "Revenda");
                    history.push("/revenda");
                    // window.open('http://revenda.poscontrole.com.br')
                  }}
                >
                  Seja uma Revenda
                </p>
              ) : (
                <p
                  className="hiperlink"
                  onClick={() => {
                    createGaEvent("link", "Cliente");
                    history.push("/");
                    // window.open('http://revenda.poscontrole.com.br')
                  }}
                >
                  Seja um Cliente
                </p>
              )}
              <div
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
              >
                <p className="hiperlink dropdown-Link">
                  Projetos <FaAngleDown />
                </p>
                <div
                  className="dropdown-menu"
                  style={{ display: isShown ? "flex" : "none" }}
                >
                  <p
                    className="hiperlink"
                    onClick={() =>
                      window.open("https://oferta.poscontrole.com.br/multipv")
                    }
                  >
                    MULTIPV
                  </p>
                  <p
                    className="hiperlink"
                    onClick={() =>
                      window.open(" https://oferta.poscontrole.com.br/smart-tef")
                    }
                  >
                    Smart TEF
                  </p>
                  <p
                    className="hiperlink"
                    onClick={() =>
                      window.open("https://oferta.poscontrole.com.br/calculadora")
                    }
                  >
                    Calculadora POS
                  </p>
                  <p
                    className="hiperlink"
                    onClick={() =>
                      window.open("https://oferta.poscontrole.com.br/projetos")
                    }
                  >
                    Projetos sob medida
                  </p>
                  <p
                    className="hiperlink"
                    onClick={() => {
                      window.open(
                        "https://oferta.poscontrole.com.br/equipamentos-homologados"
                      );
                    }}
                  >
                    Conheça mais
                  </p>
                </div>
              </div>
              <p
                className="hiperlink"
                onClick={() => {
                  createGaEvent("link", "APP POS CONTROLE");
                  window.open("https://app.poscontrole.com.br");
                }}
              >
                Login
              </p>
              {/* <p onClick={() => {createGaEvent('link', 'Revenda'); window.open('http://revenda.poscontrole.com.br')}} className='highlighted_link hiperlink'>Seja uma Revenda</p> */}
              <Button
                onClick={() => navigate("cadastre-se", 90)}
                text={"Contratar"}
              />
            </div>
          )}
        </Container>
        {size.width < 1050 && (
          <DrawerContainer>
            <div onClick={() => closeNav()} id="lateral_nav_background" />

            <div id="lateral_nav" className={"lateral_nav"}>
              <div className="options_container">
                <p
                  className="hiperlink"
                  onClick={() =>
                    window.open(
                      "https://indique-e-ganhe.poscontrole.com.br?bl_ref=L1Z50"
                    )
                  }
                >
                  Indique e Ganhe
                </p>
                {!isShownLinkRevenda ? (
                  <p
                    className="hiperlink"
                    onClick={() => {
                      createGaEvent("link", "Revenda");
                      history.push("/revenda");
                      // window.open('http://revenda.poscontrole.com.br')
                    }}
                  >
                    Seja uma Revenda
                  </p>
                ) : (
                  <p
                    className="hiperlink"
                    onClick={() => {
                      createGaEvent("link", "Cliente");
                      history.push("/");
                      // window.open('http://revenda.poscontrole.com.br')
                    }}
                  >
                    Seja um Cliente
                  </p>
                )}
                {/* <p className='hiperlink' onClick={() => navigate('como_utilizar', 90)}>Como Utilizar</p> */}
                {/* <p className='hiperlink' onClick={() => navigate('cadastre-se', 90)}>Cadastre-se</p> */}
                <div onClick={toggleIsShow} ref={dropRef}>
                  <p className="hiperlink dropdown-Link">
                    {" "}
                    Projetos {isShown ? <FaAngleUp /> : <FaAngleDown />}{" "}
                  </p>
                  <div
                    className="dropdown-menu-mobile"
                    style={{ display: isShown ? "flex" : "none" }}
                  >
                    <p
                      className="hiperlink"
                      onClick={() =>
                        window.open("https://oferta.poscontrole.com.br/multipv")
                      }
                    >
                      MULTIPV
                    </p>
                    <p
                      className="hiperlink"
                      onClick={() =>
                        window.open("https://oferta.poscontrole.com.br/smart-tef")
                      }
                    >
                      Smart TEF
                    </p>
                    <p
                      className="hiperlink"
                      onClick={() =>
                        window.open(
                          "https://oferta.poscontrole.com.br/calculadora"
                        )
                      }
                    >
                      Calculadora POS
                    </p>
                    <p
                      className="hiperlink"
                      onClick={() =>
                        window.open("https://oferta.poscontrole.com.br/projetos")
                      }
                    >
                      Projetos sob medida
                    </p>
                    <p
                      className="hiperlink"
                      onClick={() => {
                        window.open(
                          "https://oferta.poscontrole.com.br/equipamentos-homologados"
                        );
                      }}
                    >
                      Conheça mais
                    </p>
                  </div>
                </div>
                <p
                  className="hiperlink"
                  onClick={() => {
                    createGaEvent("link", "APP POS CONTROLE");
                    window.open("https://app.poscontrole.com.br");
                  }}
                  to="/login"
                >
                  Login
                </p>
                <p
                  className="hiperlink"
                  onClick={() => navigate("cadastre-se", 90)}
                  to="/login"
                >
                  Contratar
                </p>
              </div>
            </div>
          </DrawerContainer>
        )}
      </div>

    ) : (
      <></>
    )
  );
}

export default withRouter(Nav);
