import styled from 'styled-components'

export const Container = styled.div`
    max-width: 100vw;
    min-height: calc(40vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 150px;
    background: #FFFFFF;
    /* background: linear-gradient(0.6turn, #f8ae55, #f58747); */
    border-top: 50px solid lightgray;
    padding: 40px 0;
    .hiperlink {
        font-weight: 600;
        margin: 10px 10px 5px;
        text-decoration: none;
        color: black;

        &:hover {
            color: var(--color-secondary);
            border-bottom: 2px solid var(--color-secondary);
            transition: 0.1s;
            cursor: pointer;
          }
    
    }

    .footer_1{
        display: flex;
        justify-content: space-around;
        width: 700px;
        align-items: center;
        gap: 50px;
        height: 210px;
        @media (max-width: 690px) {
            flex-direction: column;
            align-items: start;
            margin: 0 12px;
        }
    }
    .footer_2{
        justify-content: center;
        align-items: center;
        display: flex;
        height: 210px;
        gap: 90px;
        width: 700px;
        margin-right: 35px;
        @media (max-width: 690px) {
            flex-direction: column;
            align-items: start;
            margin: 0 12px;
        }
    }
    .voce_pos {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;

        h3 {
            color:rgb(244, 122, 68);
        }
    }
    
    .help_pos {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        h3 {
            color: rgb(244, 122, 68);
        }

       
    }
    
    .canais_pos{
        display: flex;
        flex-direction: column;
        align-items: end;
        margin-right: 25px;
        h3 {
            color: rgb(244, 122, 68);
        }
        .social_icons {
            display: flex;
            gap: 2px;
        }

        @media (max-width: 690px) {
            align-items: start;
        }
    }
    a {
        font-weight: 600;
        margin: 5px 0;
        text-decoration: none;
        color: black;

        &:hover {
            color: var(--color-secondary);
            border-bottom: 2px solid var(--color-secondary);
            transition: 0.1s;
            cursor: pointer;
          }
    
    }


    img {
        max-height: 110px;
    }
    
    ion-icon {
        font-size: 24px;
        margin-right: 10px;
        color: var(--color-primary);
        &:hover {
            color: var(--color-secondary);
            transition: 0.1s;
            cursor: pointer;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    h3 {
        margin-bottom: 10px;
    }

    .footer_links {
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .footer_content {
        display: flex;
        flex-wrap: wrap;
    }

    .iconed_text {
        display: flex;
        margin: 14px 0;
        span {
            color: gray;
            font-size: 14px;
        }
        a {
            margin: 10px 0 5px;
        }
        ion-icon {
            cursor: default;
            color: var(--color-secondary);
        }
    }

    .partner_images {
        margin: 14px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        img {
            margin: 10px 0;
            min-height: 40px;
        }
    }

`
export const  Partners = styled.div`
    display: flex;
    background: white;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px 5%;
    .partners_container {
      flex: 1;
      margin: 40px 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h2 {
        margin-bottom: 20px;
        font-family: var(--font-family-secondary);
      }

      .partner_images {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      img {
        max-height: 30px;
        margin: 20px 40px 20px 0;
      }
    }
  `