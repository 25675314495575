import React from 'react'
import { Container } from './styles'

export default function ColoredCard({title, subTitle, icon}) {
    return (
        <Container>
            <div className='promo_price'>
               {subTitle && <h5>{subTitle}</h5>}
               {icon && <ion-icon name={icon}/>}
            </div>
            <div className='promo_name'>
                <p>{title}</p>
            </div>
        </Container>
    )
}