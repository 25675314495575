import styled from 'styled-components'

export const Container = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 30px;
    outline: none;
    border: 0px;
    background: var(--color-primary);
    color: var(--color-light);
    text-transform: uppercase;
    font-weight: 600;
    border-radius: var(--border-radius-small);
    box-shadow: var(--box-shadow-nav);
    ion-icon {
        font-size: 20px;
    }
    
    &:hover {
        cursor: pointer;
        opacity: 0.8;
        transition: 0.3s;
    }

    &:active {
        transition: 0.3s;
        opacity: 0.6;
    }

`