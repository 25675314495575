import React from "react";
import { Route, Switch } from "react-router-dom";
import { Main, Whitelabel, Checkout } from "../../pages";
import ScrollToTop from "../../custom_hooks/ScrollToTop";
// import Planos from '../../pages/revenda'

const Routes = () => {
  return (
    <>
    <ScrollToTop />
    <Switch>
      <Route path='/revenda' component={Whitelabel} />
      <Route path='/checkout' component={Checkout} />
      <Route path="/" component={Main} />
    </Switch>
    </>
  );
};
export default Routes;
